import { useLazyQuery } from '@apollo/client'
import { GET_CHANNEL_AUTHORIZATION_INFO } from '../queries'
import {
  getRedirectUri,
  handleSettingCookiesForConnection,
  setChannelStateCookies,
  setRedirectURLCookie,
} from '../../utils'
import { useCallback } from 'react'
import { InstagramAuthMethod, Service } from '../../types'

import type {
  GetAuthorizationInfoArgs,
  UseAuthorizationInfoReturn,
} from './types'
import { useDispatch, useSelector } from 'react-redux'
import type { OrchestratorRootState } from '../../../../../common/events/types'
import trackChannelCTAClicked from '../../../../../tracking/trackChannelCTAClicked'
import { useUser } from '../../../../../common/context/User'
import { getChannelFromAccount } from '../../../../../common/utils/channels'
import { setAuthRedirectUrl } from '../../../store/channelConnectionsReducer'
import { getCookie } from '../../../../../common/utils/cookies'

export default function useAuthorizationInfo(): UseAuthorizationInfoReturn {
  const user = useUser()
  const dispatch = useDispatch()

  // Get destinationUrl and cta from store if it has been set from connectChannel action
  const {
    destinationUrl,
    cta,
    selectedService,
    skipCelebrationModal,
    prefillDataAfterConnection,
  } = useSelector((state: OrchestratorRootState) => state.channelConnections)

  const [getAuthorizationInfo, { loading, data }] = useLazyQuery(
    GET_CHANNEL_AUTHORIZATION_INFO,
    {
      onCompleted(data) {
        // We need to set state cookies as redirects to 3rd party oauth
        // takes us out of our app and we lose the state.
        if (selectedService) {
          setChannelStateCookies(
            selectedService,
            data?.channelAuthorizationInfo?.state,
          )

          // Set redirect URL cookie to be able to redirect back to the same page after
          // the user has authorized the connection. We use the destinationUrl if it has been set
          // from the connectChannel action, otherwise we use the current window location.
          const url = new URL(destinationUrl || window.location.href)

          // Check if we should skip the celebration modal and prefill data after connection
          if (skipCelebrationModal) {
            url.searchParams.set('skipCelebrationModal', 'true')
          }
          if (prefillDataAfterConnection) {
            url.searchParams.set('prefillDraftData', 'true')
          }
          setRedirectURLCookie(url.toString())

          dispatch(
            setAuthRedirectUrl({
              url: data?.channelAuthorizationInfo?.url,
            }),
          )
        }
      },
    },
  )

  const handleGetAuthorizationInfo = useCallback(
    (args: GetAuthorizationInfoArgs) => {
      const redirectUri = getRedirectUri(args.service, args.authMethod)
      const channelToRefresh = getChannelFromAccount(
        user,
        args?.selectedRefreshChannelId || '',
      )
      const serverFromCookie = getCookie<string>({
        key: 'MastodonServer',
        prefix: 'oauthChannels',
      })

      const channelServer =
        args?.server || channelToRefresh?.serverUrl || serverFromCookie
      handleSettingCookiesForConnection({
        followBuffer: args?.followBuffer,
        server: channelServer,
        selectedRefreshChannelId: args.selectedRefreshChannelId,
      })

      getAuthorizationInfo({
        variables: {
          input: {
            service: args.service,
            redirectUri,
            metadata: {
              mastodonMetadata:
                args.service === Service.mastodon
                  ? {
                      server: channelServer || null,
                    }
                  : undefined,
              instagramMetadata:
                args.service === Service.instagram
                  ? {
                      apiVersion:
                        args.authMethod ?? InstagramAuthMethod.facebookLogin,
                    }
                  : undefined,
            },
          },
        },
      })

      trackChannelCTAClicked({ payload: { service: args.service, cta }, user })
    },
    [getAuthorizationInfo, cta, user],
  )

  const channelAuthorizationInfo = data?.channelAuthorizationInfo

  return {
    url: channelAuthorizationInfo?.url,
    state: channelAuthorizationInfo?.state,
    redirectUri: channelAuthorizationInfo?.redirectUri,
    handleGetAuthorizationInfo,
    isAuthorizationInfoLoading: loading,
    authorizationInfoErrorMessage:
      channelAuthorizationInfo?.userFriendlyMessage,
  }
}
