import { channelColors } from '@buffer-mono/popcorn'
import type { Service } from '~publish/gql/graphql'

export function isFacebookGroup(channel: {
  service: string
  serverUrl?: string | null
}): boolean {
  return (
    (channel.service === 'facebook' &&
      channel.serverUrl?.startsWith('https://www.facebook.com/groups')) ??
    false
  )
}

const channelServiceMap: Record<Service, string> = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter / X',
  linkedin: 'LinkedIn',
  threads: 'Threads',
  youtube: 'YouTube',
  tiktok: 'TikTok',
  pinterest: 'Pinterest',
  startPage: 'Start Page',
  bluesky: 'Bluesky',
  googlebusiness: 'Google Business Profile',
  mastodon: 'Mastodon',
}

export const channelServices = Object.keys(channelServiceMap) as Service[]

export function getChannelName(service: Service): string {
  return channelServiceMap[service]
}

export function getChannelColor(service: Service): string {
  return channelColors[service]
}
