import type { ApolloError } from '@apollo/client'
import { useCallbackRef } from '@buffer-mono/popcorn'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCommentsConfig } from '../CommentsChannelConfigContext'
import type { CommentsChannelNav } from '~publish/components/CommentsSidebar/useCommentsChannels'
import { PusherEvent, usePusherEvent } from '~publish/services/pusher'
import { useEffect, useMemo, useState } from 'react'
import { useCommentsCounts } from './useCommentsCounts'

type CommentCountsValues = {
  channelId: string | null
  count: number
  limit: number | null
}

type ApolloCountsState = {
  counts: CommentCountsValues[]
  loading: boolean
  error: ApolloError | null
}

type UseCommentCountResult = {
  counts: CommentCountsValues[] | null
  loading: boolean
  error: ApolloError | null
}

type NewCommentCountsProps = {
  channels: CommentsChannelNav[]
}

type PusherEventData = {
  channelId?: string
}

/* comment events that update the comments count */
const commentsEvents = [
  PusherEvent.COMMENT_DISMISSED,
  PusherEvent.COMMENT_REPLIED,
  PusherEvent.COMMENT_UNDISMISSED,
  PusherEvent.DISMISS_ALL_COMMENTS_BY_POST,
  PusherEvent.DISMISS_ALL_COMMENTS_BY_CHANNEL,
  PusherEvent.DISMISS_ALL_COMMENTS_BY_ORGANIZATION,
]

/**
 * Hook to fetch the number of new comments for an array of channels.
 * A new comment is an unreplied comment that has been created in the last 24 hours.
 *
 * @param channels - The channels to fetch comments count for
 * @returns The number of comments for the channels, or null if loading/error/unsupported
 *
 * @example
 * const { counts, loading, error } = useNewCommentsCounts({ channels: mockChannels })
 **/
export function useNewCommentsCounts({
  channels,
}: NewCommentCountsProps): UseCommentCountResult {
  const organizationId = useOrganizationId()
  const {
    config: { isSupportedInComments },
  } = useCommentsConfig()

  const supportedChannels = useMemo(
    () => channels.filter(isSupportedInComments),
    [channels],
  )
  const supportedChannelIds = useMemo(
    () => supportedChannels.map((channel) => channel.id),
    [supportedChannels],
  )

  const [state, setState] = useState<ApolloCountsState>({
    counts: [],
    loading: false,
    error: null,
  })

  const { fetchCounts } = useCommentsCounts()

  const fetchAndUpdateCounts = useCallbackRef(
    async ({ channelIds }: { channelIds: string[] }) => {
      if (!organizationId || !channelIds.length) {
        return
      }

      setState((prev) => ({ ...prev, loading: true }))

      try {
        const { data, error: queryError } = await fetchCounts({
          organizationId,
          filters: {
            channelIds,
            status: ['new'],
          },
        })

        if (!data) {
          return
        }

        // update the counts for the changed channelIds
        setState((prev) => {
          const countsMap = new Map(
            prev.counts.map((count) => [count.channelId, count]),
          )

          data.commentsCounts.counts.forEach((count) => {
            if (count.channelId && channelIds.includes(count.channelId)) {
              countsMap.set(count.channelId, count)
            }
          })

          return {
            counts: Array.from(countsMap.values()),
            loading: false,
            error: queryError || null,
          }
        })
      } catch (error) {
        setState((prev) => ({
          ...prev,
          loading: false,
          error: error as ApolloError,
        }))
      }
    },
  )

  useEffect(() => {
    fetchAndUpdateCounts({ channelIds: supportedChannelIds })
  }, [fetchAndUpdateCounts, supportedChannelIds])

  /**
   * Subscription to COMMENTS events that update the comments count
   */
  usePusherEvent([...commentsEvents], ({ channelId }: PusherEventData) => {
    if (channelId) {
      // channelId should be in the supportedChannelIds
      if (!supportedChannelIds.includes(channelId)) {
        return
      }
      fetchAndUpdateCounts({ channelIds: [channelId] })
    } else {
      fetchAndUpdateCounts({ channelIds: supportedChannelIds })
    }
  })

  return {
    counts: state.counts.length > 0 ? state.counts : null,
    loading: state.loading,
    error: state.error,
  }
}
