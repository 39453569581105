import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import type { Service } from '~publish/gql/graphql'
import { actions } from '~publish/legacy/profiles-disconnected-modal/reducer'

type ReconnectOptions = {
  id: string
  service: Service
  cta?: CTA
  authMethod?: string
}

export const useReconnectChannel = (): ((opts: ReconnectOptions) => void) => {
  const dispatch = useDispatch()

  const handleReconnectChannel = useCallback(
    ({ id, service, cta, authMethod }: ReconnectOptions) => {
      if (window.appshell?.actions) {
        const { actions } = window.appshell || {}

        actions.connectChannel({
          selectedService: service.toLowerCase() as Parameters<
            typeof actions.connectChannel
          >[0]['selectedService'],
          isRefreshingConnection: true,
          cta,
          authMethod,
        })
      }

      dispatch(
        actions.reconnectProfile({
          id,
          service,
        }),
      )
    },
    [dispatch],
  )

  return handleReconnectChannel
}
