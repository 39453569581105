import React from 'react'
import clsx from 'clsx'
import {
  Paragraph,
  Heading,
  Button,
  useBreakpoint,
  Flex,
  Share2Icon,
  IconButton,
} from '@buffer-mono/popcorn'
import { getPublishUrl, isInPublish } from '../../../../../common/utils/urls'

import type { WeeklyPostingStreakData } from '../StreakWidget'
import { StreakHeader } from './StreakHeader'
import styles from '../StreakWidget.module.css'
import { useSplitEnabled } from '@buffer-mono/features'
import { useUser } from '../../../../../common/context/User'

interface StreakContent {
  heading?: string
  text: string
  textHasHtml?: boolean
  cta?: string
  badge?: {
    text: string
    color?: 'green' | 'yellow' | 'gray'
  }
  share?: boolean
}

const getStreakContent = (
  streak: WeeklyPostingStreakData['weeklyPostingStreak'] | undefined,
): StreakContent | null => {
  if (!streak) return null

  switch (streak.userStreakState) {
    case 'NewStreakNoProgress':
      return {
        heading: 'Keep posting,\nbuild a streak!',
        text: 'Post each week to grow your streak! Miss a week and it resets.',
        cta: 'Schedule Post',
      }
    case 'NewStreakOnTrack':
      return {
        heading: 'Great job!',
        text: 'Your streak will begin once the post you scheduled is published.',
        badge: {
          text: 'On track',
          color: 'green',
        },
      }
    case 'NewStreakOnTrackAndAhead':
      return {
        heading: 'Look at you go!',
        text: "You're on track for next week, too. Keep up the great work.",
        badge: {
          text: 'Ahead for next week',
          color: 'green',
        },
      }
    case 'ExistingStreakOnTrack':
      return {
        heading: `${streak.count} week streak`,
        badge: {
          text: 'On track',
          color: 'green',
        },
        text: 'Add to your streak by scheduling a post for next week.',
        share: true,
      }
    case 'ExistingStreakOnTrackAndAhead':
      return {
        heading: `${streak.count} week streak`,
        badge: {
          text: 'Ahead for next week',
          color: 'green',
        },
        text: "Look at you go! You're on track for next week, too.",
        cta: 'Schedule Post',
        share: true,
      }
    case 'NewStreakHit':
      return {
        heading: "You've started a streak! 🎉",
        text: 'Schedule at least one post each week to keep your streak going.',
        cta: 'Schedule Post',
        share: true,
      }
    case 'NewStreakHitAndAhead':
      return {
        heading: "You've started a streak! 🎉",
        text: "Look at you go! You're on track for next week. ",
        badge: {
          text: 'Ahead for next week',
          color: 'green',
        },
        share: true,
      }
    case 'ExistingStreakHit':
      return {
        heading: `${streak.count} week streak`,
        text: 'Great job! Schedule a post for next week to keep the streak going.',
        cta: 'Schedule Post',
        share: true,
      }
    case 'ExistingStreakHitAndAhead':
      return {
        heading: `${streak.count} week streak`,
        badge: {
          text: 'Ahead for next week',
          color: 'green',
        },
        text: "Look at you go! You're on track for next week, too.",
        share: true,
      }
    case 'ExistingStreakAtRisk':
      return {
        heading: `${streak.count} week streak`,
        text: 'Schedule a post for this week to keep your streak alive! Need ideas? <a href="https://buffer.com/resources/social-media-content-types/" target="_blank" rel="noreferrer">Check out these tips.</a>',
        textHasHtml: true,
        badge: {
          text: 'At risk',
          color: 'yellow',
        },
        cta: 'Schedule Post',
      }
    case 'StreakLost':
      return {
        heading: `Start a new streak`,
        text: 'Stuck for ideas?<br/><a href="https://buffer.com/resources/social-media-content-types/" target="_blank" rel="noreferrer">Check out these tips.</a>',
        textHasHtml: true,
        badge: {
          text: `Last streak: ${streak.previousCount}`,
          color: 'gray',
        },
        cta: 'Schedule Post',
      }
    default:
      console.warn('Unexpected streak status', streak)
      return null
  }
}

interface StreakWidgetContentProps {
  streak: NonNullable<WeeklyPostingStreakData['weeklyPostingStreak']>
  onClose: () => void
}

export const StreakWidgetContent = ({
  streak,
  onClose,
}: StreakWidgetContentProps): JSX.Element | null => {
  const { isEnabled: isResponsiveNavEnabled } = useSplitEnabled(
    'responsive-main-nav',
  )
  const user = useUser()
  const channelsLength = user.currentOrganization?.channels?.length

  const breakpoint = useBreakpoint()
  const isDrawer =
    breakpoint !== 'md' &&
    breakpoint !== 'lg' &&
    breakpoint !== 'xl' &&
    isResponsiveNavEnabled

  const content = getStreakContent(streak)
  if (!content) return null

  const handleCtaClick = (): void => {
    const cta = 'publish-appshell-streakwidget-createPost-1'
    const newPostRoute = `post/new?&cta=${cta}`
    const isInPublishApp = isInPublish()

    if (window.__openComposer && isInPublishApp) {
      window.__openComposer?.({
        cta,
      })
      onClose()
    } else {
      const redirectUrl = getPublishUrl(newPostRoute)
      window.location.assign(redirectUrl)
    }
  }

  const handleShareClick = (): void => {
    const cta = 'publish-appshell-streakwidget-share-1'
    // Select all channels if there are less than 3
    // Otherwise, select the first 3
    const channelId = channelsLength && channelsLength > 3 ? undefined : 'all'
    const imageUrl = `https://buffer-publish.s3.us-east-1.amazonaws.com/images/streaks/w${streak.count}-p.png`
    const text = `I've reached a ${streak.count}-week posting streak with Buffer!`
    const altText = `An image of a plant growing into a ${streak.count}-week posting streak with Buffer`
    const newPostRoute = `post/new?cta=${cta}&text=${text}&picture=${imageUrl}&channelId=${channelId}`
    const isInPublishApp = isInPublish()

    if (window.__openComposer && isInPublishApp) {
      window.__openComposer?.({
        cta,
        channels: channelId,
        prefillPostData: {
          text,
          media: {
            picture: imageUrl,
            thumbnail: imageUrl,
            alt_text: altText,
            width: '4000',
            height: '4000',
            uploaded: 'true',
            progress: '100',
          },
        },
      })
      onClose()
    } else {
      const redirectUrl = getPublishUrl(newPostRoute)
      window.location.assign(redirectUrl)
    }
  }

  return (
    <div
      className={clsx(
        styles.popoverContent,
        isDrawer && styles.popoverContentMobile,
      )}
    >
      <StreakHeader>
        <StreakHeader.Background streak={streak} />
        <StreakHeader.Content streak={streak} />
      </StreakHeader>

      <div className={styles.popoverTextContainer}>
        <div>
          <Heading
            align="center"
            size="medium"
            as="h3"
            className={styles.popoverTitle}
          >
            {content.heading}
          </Heading>
          {content.badge && (
            <div
              className={clsx(
                styles.popoverStatus,
                styles[content.badge.color ?? 'green'],
              )}
            >
              {content.badge.text}
            </div>
          )}
        </div>
        <Paragraph color="subtle" size="sm" className={styles.popoverText}>
          {content.textHasHtml ? (
            <div dangerouslySetInnerHTML={{ __html: content.text }} />
          ) : (
            content.text
          )}
        </Paragraph>
      </div>
      <Flex align="center" justify="center" gap="xs">
        {content.cta && (
          <Button variant="primary" size="medium" onClick={handleCtaClick}>
            {content.cta}
          </Button>
        )}
        {content.share && content.cta && (
          <IconButton
            label="Share your streak"
            variant="secondary"
            size="medium"
            onClick={handleShareClick}
          >
            <Share2Icon />
          </IconButton>
        )}
        {content.share && !content.cta && (
          <Button variant="primary" size="medium" onClick={handleShareClick}>
            <Share2Icon />
            Share Your Streak
          </Button>
        )}
      </Flex>
    </div>
  )
}
