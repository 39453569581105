import { channelServices } from '~publish/helpers/channels'
import type { Service } from '~publish/gql/graphql'

import type { ChannelType } from '../../../pages/Comments/types'

export function filterChannels(
  channels: ChannelType[],
  searchQuery: string | null | undefined,
): ChannelType[] {
  if (!searchQuery || searchQuery.trim() === '') return channels

  return channels.filter(
    (channel) =>
      channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      channel.service.toLowerCase().includes(searchQuery.toLowerCase()),
  )
}

export const getChannelSuggestions = (
  channels: ChannelType[],
): Service[] | null => {
  if (channels.length > 3) return null

  const connectedChannelServices = channels.map((channel) => channel.service)

  return channelServices.filter(
    (service) => !connectedChannelServices.includes(service),
  )
}

export const getEngageUrl = (channelId: string): string => {
  const [, ...domainParts] = window.location.hostname.split('.')
  const domain = domainParts.join('.')

  return `https://engage.${domain}/${channelId}`
}
