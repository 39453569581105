// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const LinkStripeIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'link-stripe'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M12.0948 9.22561C12.0948 8.84961 12.3998 8.54401 12.7589 8.54401C13.1179 8.54401 13.423 8.85121 13.423 9.22561C13.423 9.60001 13.1287 9.91521 12.7589 9.91521C12.3891 9.91521 12.0948 9.60961 12.0948 9.22561ZM10.124 8.64H11.2797V15.36H10.124V8.64ZM13.3428 10.56H12.178V15.36H13.3428V10.56ZM21.7173 12.7968C22.594 12.2368 23.1903 11.4032 23.426 10.5584H22.2612C21.9576 11.3648 21.2612 11.9712 20.4954 12.2288V8.63841H19.3305V15.3584H20.4954V13.36C21.3844 13.5904 22.0871 14.3888 22.3274 15.3584H23.5C23.3213 14.3408 22.651 13.3888 21.7173 12.7968ZM15.4075 11.0976C15.7126 10.6768 16.3074 10.432 16.7897 10.432C17.6895 10.432 18.4337 11.1152 18.4353 12.1472V15.3584H17.2704V12.4144C17.2704 11.9904 17.0886 11.5008 16.4985 11.5008C15.8051 11.5008 15.406 12.1392 15.406 12.8864V15.3616H14.2411V10.5632H15.4075V11.0976Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.20416 12C8.20416 14.2091 6.47952 16 4.35208 16C2.22464 16 0.5 14.2091 0.5 12C0.5 9.79086 2.22464 8 4.35208 8C6.47952 8 8.20416 9.79086 8.20416 12ZM2.98686 9.60002H4.17485C4.46761 10.528 5.28271 11.3344 6.28579 11.4976V12.5008C5.28425 12.6656 4.46915 13.472 4.17485 14.4H2.98686C3.21799 13.3968 3.89134 12.5392 4.73726 12C3.89288 11.4608 3.21799 10.6032 2.98686 9.60002Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
LinkStripeIcon.displayName = 'LinkStripeIcon'
export { LinkStripeIcon }
