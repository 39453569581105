import { useLazyQuery, type QueryResult } from '@apollo/client'
import { graphql } from '~publish/graphql'
import type { CommentStatus } from '~publish/gql/graphql'

type CommentsQueryResult = {
  commentsCounts: {
    counts: Array<{
      channelId: string
      count: number
      limit: number
    }>
  }
}

type CommentsFilters = {
  channelIds?: string[]
  postId?: string[]
  status?: CommentStatus[]
  publishedAt?: {
    start?: string
    end?: string
  }
}

type CommentsQueryVariables = {
  input: {
    organizationId: string
    filters?: CommentsFilters
  }
}

type UseCommentsCountsReturn = {
  fetchCounts: (params: {
    organizationId: string
    filters?: CommentsFilters
  }) => Promise<QueryResult<CommentsQueryResult, CommentsQueryVariables>>
}

export const CommentsCountsQuery = graphql(`
  query CommentsCountsQuery($input: CommentsCountsInput!) {
    commentsCounts(input: $input) {
      counts {
        channelId
        count
        limit
      }
    }
  }
`)

/**
 * Hook to fetch the comments counts for a given organization and filters.
 * It could be used to fetch the counts with the following filters:
 * - channelIds
 * - postId
 * - status
 * - publishedAt
 * @returns {UseCommentsCountsReturn} An object containing the fetchCounts function
 *
 * @example
 * const { fetchCounts } = useCommentsCounts()
 * const { data, loading, error } = await fetchCounts({
 *   organizationId: '123',
 *   filters: { channelIds: ['123', '456'] },
 * })
 */
export function useCommentsCounts(): UseCommentsCountsReturn {
  const [getCommentCounts] = useLazyQuery<
    CommentsQueryResult,
    CommentsQueryVariables
  >(CommentsCountsQuery, { fetchPolicy: 'no-cache' })

  const fetchCounts = async (params: {
    organizationId: string
    filters?: CommentsFilters
  }): Promise<QueryResult<CommentsQueryResult, CommentsQueryVariables>> => {
    const { organizationId, filters } = params

    return getCommentCounts({
      variables: {
        input: {
          organizationId,
          filters,
        },
      },
    })
  }

  return { fetchCounts }
}
