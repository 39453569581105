import React from 'react'
import {
  Flex,
  Skeleton,
  Card,
  Text,
  Button,
  AchIcon,
  CreditCardIcon,
  AmazonPayIcon,
  AlipayIcon,
  CashappIcon,
  Notice,
  LinkStripeIcon,
  Separator,
} from '@buffer-mono/popcorn'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'
import styles from '../Settings.module.css'
import {
  useGetPortalSession,
  PortalOptions,
} from '../hooks/useGetPortalSession'
import { getSettingsUrl } from '../util/getSettingsUrl'
import { capitalize } from '~publish/helpers/capitalize'

export const PaymentDetails_Billing = graphql(/* GraphQL */ `
  fragment PaymentDetails_Billing on Billing {
    paymentDetails {
      hasPaymentDetails
      creditCard {
        brand
        last4
        expMonth
        expYear
      }
      paymentMethod
    }
  }
`)

const paymentMethodNames = new Map<string, string>([
  ['bank', 'Bank Account'],
  ['link', 'Link'],
  ['amazonPay', 'Amazon Pay'],
  ['alipay', 'Alipay'],
  ['cashApp', 'Cash App'],
])

const formatPaymentMethodName = (paymentMethod: string): string => {
  return paymentMethodNames.get(paymentMethod) ?? capitalize(paymentMethod)
}

const PaymentMethodIcon = ({
  paymentMethod,
}: {
  paymentMethod: string | null
}): JSX.Element => {
  if (paymentMethod === 'bank') {
    return <AchIcon size="medium" />
  }
  if (paymentMethod === 'link') {
    return <LinkStripeIcon size="medium" />
  }
  if (paymentMethod === 'amazonPay') {
    return <AmazonPayIcon size="medium" />
  }
  if (paymentMethod === 'alipay') {
    return <AlipayIcon size="medium" />
  }
  if (paymentMethod === 'cashApp') {
    return <CashappIcon size="medium" />
  }
  return <CreditCardIcon size="medium" />
}

const CreditCardDetails = ({
  brand,
  last4,
  expMonth,
  expYear,
}: {
  brand?: string
  last4?: string
  expMonth?: string
  expYear?: string
}): JSX.Element => {
  // TODO: can we make these non-nullable in the schema?
  if (!brand || !last4 || !expMonth || !expYear) {
    return <Text>Card</Text>
  }
  return (
    <>
      <Flex gap="xs" direction="row" align="center">
        <PaymentMethodIcon paymentMethod={'card'} />
        <Text>
          {capitalize(brand)} ... ... ... {last4}
        </Text>
      </Flex>
      <Flex gap="xs" direction="row" align="center">
        <Text>
          Expires {expMonth}/{expYear}
        </Text>
      </Flex>
    </>
  )
}

export const PaymentDetails = ({
  paymentPastDue,
  canAddPaymentDetails,
  loading,
  data,
}: {
  paymentPastDue: boolean
  canAddPaymentDetails: boolean
  loading: boolean
  data?: FragmentOf<typeof PaymentDetails_Billing> | null
}): JSX.Element => {
  const paymentDetails = data
    ? readFragment(PaymentDetails_Billing, data).paymentDetails
    : null

  const { getCustomerPortalSession, loading: portalSessionLoading } =
    useGetPortalSession({
      portalOption: PortalOptions.MAIN_PAGE,
    })

  const isCreditCard = paymentDetails && !!paymentDetails.creditCard
  const showPaymentMethod = paymentDetails && paymentDetails.hasPaymentDetails

  if (!paymentDetails || !paymentDetails.hasPaymentDetails) {
    return <Separator className={styles.fullWidth} />
  }

  return (
    <>
      <Separator className={styles.fullWidth} />
      {paymentPastDue && (
        <Notice variant="warning">
          <Text>
            Your payment method is past due. Please update your payment method
            to continue using Buffer.
          </Text>
        </Notice>
      )}
      <Flex gap="xs" direction="row" align="center" justify="between" fullWidth>
        <Text size="lg" weight="bold">
          Payment Method
        </Text>
        <Button
          size="medium"
          variant="secondary"
          onClick={(): void => {
            getCustomerPortalSession(getSettingsUrl())
          }}
          disabled={portalSessionLoading || !canAddPaymentDetails}
        >
          {showPaymentMethod ? 'Update Method' : 'Add Method'}
        </Button>
      </Flex>
      <Skeleton show={loading}>
        <Card className={styles.fullWidth}>
          <Flex
            gap="xs"
            direction="row"
            align="center"
            justify="between"
            fullWidth
          >
            {isCreditCard ? (
              <CreditCardDetails
                brand={paymentDetails.creditCard?.brand}
                last4={paymentDetails.creditCard?.last4}
                expMonth={paymentDetails.creditCard?.expMonth}
                expYear={paymentDetails.creditCard?.expYear}
              />
            ) : (
              <Flex gap="xs" direction="row" align="center">
                <PaymentMethodIcon
                  paymentMethod={paymentDetails.paymentMethod}
                />
                <Text>
                  {/* TODO: can we make this non-nullable in the schema? */}
                  {paymentDetails.paymentMethod &&
                    formatPaymentMethodName(paymentDetails.paymentMethod)}
                </Text>
              </Flex>
            )}
          </Flex>
        </Card>
      </Skeleton>
      <Separator className={styles.fullWidth} />
    </>
  )
}

export default PaymentDetails
