import React, { useCallback, useState } from 'react'

import {
  Button,
  Dialog,
  Flex,
  Form,
  Input,
  Link,
  CriticalIcon,
  Text,
  useControllableState,
  Notice,
  RadioCard,
} from '@buffer-mono/popcorn'
import { TrackRender } from '~publish/hooks/tracking/useTrackComponentRendered'
import type {
  WrappedCreateFeedResponse,
  CreateFeedMutationFailureFeedback,
} from '../../hooks/useCreateFeed'
import type { FeedCTA } from '../../constants'
import { SuggestedFeedCard } from './SuggestedFeedCard'

import styles from './SubscribeToFeedDialog.module.css'

type SubscribeToFeedDialogContentProps = {
  onOpenChange?: (open: boolean) => void
  existingFeeds: { url: string; id: string }[]
  onAddFeed: (args: {
    feedUrl: string
    cta: FeedCTA
  }) => Promise<WrappedCreateFeedResponse>
}

export const SubscribeToFeedDialogContent = ({
  existingFeeds,
  onAddFeed,
  onOpenChange,
}: SubscribeToFeedDialogContentProps): JSX.Element => {
  const [feedUrl, setFeedUrl] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  )
  const [submitError, setSubmitError] = useState<
    CreateFeedMutationFailureFeedback | undefined
  >(undefined)

  const existingFeedUrls = React.useMemo(() => {
    return existingFeeds.map((feed) => feed.url)
  }, [existingFeeds])

  const handleFeedUrlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFeedUrl(event.target.value)
      setValidationError(undefined)
    },
    [],
  )

  const validate = React.useCallback(() => {
    if (existingFeedUrls.includes(feedUrl)) {
      setValidationError('You have already added this feed')
      return false
    }
    if (!feedUrl || feedUrl.length === 0) {
      setValidationError('Feed URL is required')
      return false
    }
    try {
      if (!feedUrl.match(/^[^.]+\.[^.]+/)) {
        setValidationError(
          'URL must end in a valid top level domain, such as .com or .io',
        )
        return false
      }

      if (new URL(feedUrl) === null) {
        setValidationError('Invalid URL')
        return false
      }
    } catch (e) {
      setValidationError(
        e instanceof Error ? e.message : 'An unexpected error occurred.',
      )
      return false
    }

    return true
  }, [feedUrl, existingFeedUrls])

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      setIsSubmitting(true)

      if (!validate()) {
        setIsSubmitting(false)
        return
      }
      const addFeedResult = await onAddFeed({ feedUrl, cta: 'add-from-url' })
      if (addFeedResult.error) {
        setSubmitError(addFeedResult.error)
        setIsSubmitting(false)
        return
      }
      onOpenChange?.(false)
    } catch (e) {
      setSubmitError({
        title: 'Failed to create feed',
        description:
          e instanceof Error ? e.message : 'An unexpected error occurred.',
        debug: e instanceof Error ? e.message : 'An unexpected error occurred.',
      })
      setIsSubmitting(false)
      return
    }

    setIsSubmitting(false)
    setSubmitError(undefined)
  }, [feedUrl, onAddFeed, onOpenChange, validate])

  const hasSuggestions =
    submitError?.suggestedRSSFeeds && submitError?.suggestedRSSFeeds?.length > 0

  const inputShowValidationError = !!(
    (validationError || submitError) &&
    !hasSuggestions
  )

  return (
    <Dialog.Content size="medium" asChild>
      <Form onSubmit={handleSubmit} aria-disabled={isSubmitting}>
        <TrackRender componentName="SubscribeToFeedDialog" />
        <Dialog.Header>
          <Dialog.Title>Add Feed</Dialog.Title>
          <Dialog.Description>
            {`RSS URLs are links to automatically get updates from a blog or news site. The link is usually made up of the website’s domain name, followed by "/feed" or "/rss". `}
            <Link
              external
              href="https://support.buffer.com/article/908-using-content-feeds-in-buffer#How-to-find-an-RSS-feed-URL-uKSVg"
            >
              Learn More
            </Link>
          </Dialog.Description>
        </Dialog.Header>
        <Dialog.Body>
          <Flex direction="column" gap="lg">
            <Flex gap="md" direction="column" fullWidth>
              {submitError && !hasSuggestions && (
                <Notice
                  variant="error"
                  id="feed-url-error"
                  className={styles.submitErrorNotice}
                >
                  <strong>{submitError.title}. </strong>
                  {submitError.description}
                </Notice>
              )}
              <Form.Field name="feedUrl" className={styles.inputField}>
                <Form.Label htmlFor="new-feed-url">RSS URL</Form.Label>
                <Form.Control>
                  <Flex direction="column" gap="sm" fullWidth>
                    <Flex direction="column" gap="xs" fullWidth>
                      <Input
                        id="new-feed-url"
                        placeholder="https://"
                        size="large"
                        value={feedUrl}
                        onChange={handleFeedUrlChange}
                        disabled={isSubmitting}
                        aria-describedby={
                          inputShowValidationError
                            ? 'feed-url-error'
                            : undefined
                        }
                        aria-invalid={inputShowValidationError}
                        className={styles.input}
                      />
                      {validationError && (
                        <Text
                          as="p"
                          size="sm"
                          color="critical"
                          id="feed-url-error"
                          className={styles.validationText}
                        >
                          <CriticalIcon
                            size="xsmall"
                            className={styles.warningIcon}
                          />{' '}
                          {validationError}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Form.Control>
              </Form.Field>
            </Flex>
            {hasSuggestions && (
              <Flex direction="column" gap="xs" fullWidth>
                <Text color="subtle">Related feeds found</Text>
                <RadioCard.Group
                  className={styles.radioGroupCard}
                  onValueChange={(value): void => setFeedUrl(value)}
                >
                  {submitError?.suggestedRSSFeeds?.map((suggestion) => (
                    <SuggestedFeedCard
                      key={suggestion.url}
                      suggestion={suggestion}
                    />
                  ))}
                </RadioCard.Group>
              </Flex>
            )}
          </Flex>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close>
            <Button
              variant="tertiary"
              size="large"
              disabled={isSubmitting}
              onClick={(): void => onOpenChange?.(false)}
            >
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="primary"
            size="large"
            type="submit"
            loading={isSubmitting}
          >
            Add Feed
          </Button>
        </Dialog.Footer>
      </Form>
    </Dialog.Content>
  )
}

export type SubscribeToFeedDialogProps = SubscribeToFeedDialogContentProps & {
  enabled?: boolean
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children: JSX.Element
}

export const SubscribeToFeedDialog = ({
  enabled = true,
  children,
  ...props
}: SubscribeToFeedDialogProps): JSX.Element => {
  const [isOpen, setIsOpen] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
  })

  if (!enabled) {
    return children
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {/* asChild needs to be false to support multiple conditional dialogs around one trigger */}
      <Dialog.Trigger asChild={false} className={styles.trigger}>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <SubscribeToFeedDialogContent {...props} onOpenChange={setIsOpen} />
      </Dialog.Portal>
    </Dialog>
  )
}
