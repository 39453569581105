import React from 'react'
import clsx from 'clsx'
import { NavLink, useLocation } from 'react-router-dom'

import {
  ChannelAvatar,
  Flex,
  LockIcon,
  Sidebar,
  Text,
  WarningIcon,
  Tooltip,
  VisuallyHidden,
  Badge,
  useSidebar,
  Link,
  CriticalIcon,
} from '@buffer-mono/popcorn'

import {
  parseQueryParams,
  type QueryParamValue,
  serializeQueryParams,
} from '~publish/hooks/useQueryParam'
import { isFacebookGroup } from '~publish/helpers/channels'
import { commentsChannel } from '~publish/legacy/routes'

import type { CommentsChannelNav } from '../useCommentsChannels'
import { getEngageUrl } from './helpers'
import { useCommentsConfig } from '../../../pages/Comments/CommentsChannelConfigContext'

import styles from './ChannelNav.module.css'

function requiresRefreshing(channel: CommentsChannelNav): boolean {
  return channel.service === 'twitter' && channel.apiVersion !== '2'
}

function CommentsCount({
  count,
  className,
}: {
  count: number
  className?: string
}): JSX.Element {
  return (
    <span>
      <Badge variant="brand" size="xsmall" className={className}>
        {count > 99 ? '99+' : count}
      </Badge>
      <VisuallyHidden> new comments</VisuallyHidden>
    </span>
  )
}

function ChannelSuffix({
  channel,
  children,
}: {
  channel: CommentsChannelNav
  children?: React.ReactNode
}): JSX.Element {
  if (channel.isDisconnected) {
    return (
      <Tooltip content="This channel is disconnected">
        <WarningIcon size="small" color="critical" />
      </Tooltip>
    )
  }

  if (channel.isLocked) {
    return (
      <Tooltip content="This channel is locked">
        <LockIcon size="small" />
      </Tooltip>
    )
  }

  if (requiresRefreshing(channel)) {
    return (
      <Tooltip content="This channel requires refreshing">
        <CriticalIcon size="small" color="critical" />
      </Tooltip>
    )
  }

  return <>{children}</>
}

interface ChannelNavItemProps
  extends Omit<React.ComponentProps<typeof Sidebar.ListItem>, 'children'> {
  channel: CommentsChannelNav
}

function ChannelNavItemContent({
  channel,
}: {
  channel: CommentsChannelNav
}): JSX.Element {
  const serverUrl = isFacebookGroup(channel) ? null : channel.serverUrl
  const additionalInfo = channel.locationData?.location ?? serverUrl

  return (
    <Flex direction="column" gap="2xs">
      <Text weight="medium" data-testid="channel-name" lineHeight="tight">
        {channel.name}
      </Text>
      {additionalInfo && (
        <Text size="sm" color="subtle" lineHeight="tight">
          {additionalInfo}
        </Text>
      )}
    </Flex>
  )
}

function getTooltipContent(service: string, type: string): string {
  if (service === 'facebook' && type === 'group') {
    return 'Facebook Groups are not available for Comments yet.'
  }

  if (service === 'instagram' && type === 'profile') {
    return 'Instagram Personal Profiles are not available for Comments yet.'
  }

  return 'This channel is not available for Comments yet.'
}

type ChannelNavigationItemProps = {
  channel: CommentsChannelNav
  className?: string
  commentCounts: number | null
}

export function ChannelNavItem({
  channel,
  className,
  commentCounts,
}: ChannelNavigationItemProps): JSX.Element {
  const { state } = useSidebar()
  const url = useChannelPageRoute(channel.id, { keepSearch: true })
  const {
    config: { isSupportedInEngage, isChannelSupported },
  } = useCommentsConfig()

  const isChannelFromEngage = isSupportedInEngage(channel)
  const shouldDisableChannel = !isChannelSupported(channel)

  const buttonProps = {
    size: 'large' as const,
    disabled: shouldDisableChannel,
    prefix: (
      <ChannelAvatar
        alt={channel.service}
        channel={channel.service}
        src={channel.avatar}
        size="small"
      />
    ),
    suffix: (
      <ChannelSuffix channel={channel}>
        {commentCounts && <CommentsCount count={commentCounts} />}
      </ChannelSuffix>
    ),
  }

  const Item = (
    <Sidebar.ListItem
      id={channel.id}
      className={clsx(styles.channel, className)}
    >
      {isChannelFromEngage && (
        <Sidebar.Button
          {...buttonProps}
          as={Link}
          href={getEngageUrl(channel.id)}
          className={styles.engageLink}
        >
          <ChannelNavItemContent channel={channel} />
        </Sidebar.Button>
      )}
      {!isChannelFromEngage && (
        <Sidebar.Button {...buttonProps} as={NavLink} to={url}>
          <ChannelNavItemContent channel={channel} />
        </Sidebar.Button>
      )}

      {/* This is the count badge when the sidebar is collapsed */}
      {commentCounts !== null &&
        commentCounts !== undefined &&
        commentCounts > 0 && (
          <CommentsCount
            count={commentCounts}
            className={clsx(
              styles.collapsedBadge,
              state === 'expanded' && styles.collapsedBadgeHidden,
            )}
          />
        )}
    </Sidebar.ListItem>
  )

  if (shouldDisableChannel) {
    return (
      <Tooltip
        content={getTooltipContent(channel.service, channel.type)}
        side="right"
        delay={400}
      >
        {Item}
      </Tooltip>
    )
  }

  return Item
}

function useChannelPageRoute(
  channelId: string,
  { keepSearch = false } = {},
): string {
  const currentSearch = parseQueryParams(useLocation().search)
  const search: Record<string, QueryParamValue> = {}

  // Preserve any valid query params defined in the route
  for (const { param, values: possibleValues } of commentsChannel.queryParams) {
    const value = currentSearch[param]

    if (possibleValues && possibleValues.includes(value as any)) {
      search[param] = value
    } else if (!possibleValues && value) {
      search[param] = value
    }
  }

  return (
    commentsChannel.buildPathname({ channelId }) +
    (keepSearch ? `?${serializeQueryParams(search)}` : '')
  )
}
