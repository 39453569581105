import React, { useEffect, useState } from 'react'

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useUser } from '../../../../common/context/User'
import { MODALS } from '../../../../common/types'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'
import {
  Button,
  Dialog,
  Flex,
  Form,
  RadioGroup,
  Text,
  Textarea,
} from '@buffer-mono/popcorn'

import styles from './index.module.css'

const ChurnSurveyModal = ({
  dismissModal,
}: {
  dismissModal: () => void
}): JSX.Element => {
  const [reason, setReason] = useState('')
  const [details, setDetails] = useState('')
  const [isValid, setIsValid] = useState(false)

  const onOpenChange = (open: boolean): void => {
    if (open) return
    dismissModal()
  }

  const { modalData: data, openModal } = useModalManager()

  const isOnTrial = data?.isOnTrial

  const onReasonChange = (value: string): void => {
    setReason(value)
    setIsValid(true)
  }

  const onDetailsChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setDetails(ev.target.value)
  }

  const account = useUser()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const organizationId = account?.currentOrganization?.id || ''
  useEffect(() => {
    if (account?.currentOrganization) {
      BufferTracker.churnSurveyViewed({
        organizationId,
        ...commonTrackingProperties,
      })
    }
  }, [account?.currentOrganization, organizationId, commonTrackingProperties])

  return (
    <Dialog open={true} onOpenChange={onOpenChange}>
      <Dialog.Content portal={true} className={styles.modalContent}>
        <Dialog.Header>
          <Dialog.Title>We are sad to see you go!</Dialog.Title>
          <Dialog.Description>
            We would love to understand why so we can make Buffer better. Would
            you mind sharing the reason with us?
          </Dialog.Description>
        </Dialog.Header>
        <Dialog.Body>
          <Flex direction="column" gap="sm">
            <Text>I am leaving because:</Text>
            <RadioGroup onChange={onReasonChange}>
              <Flex direction="column" gap="sm">
                <RadioGroup.Item value="extenuating-circumstances">
                  Extenuating Circumstances (e.g. illness, budget cuts, etc.)
                </RadioGroup.Item>
                <RadioGroup.Item value="not-using-anymore">
                  I don&apos;t use the product anymore
                </RadioGroup.Item>
                <RadioGroup.Item value="too-expensive">
                  Too expensive
                </RadioGroup.Item>
                <RadioGroup.Item value="missing-features">
                  Missing features
                </RadioGroup.Item>
                <RadioGroup.Item value="bugs">
                  Bugs or usability issues
                </RadioGroup.Item>
                <RadioGroup.Item value="others">Other</RadioGroup.Item>
              </Flex>
            </RadioGroup>
            <Form.Field name="details" className={styles.commentField}>
              <Form.Label>Any other details:</Form.Label>
              <Form.Control>
                <Textarea
                  size="large"
                  value={details}
                  onChange={onDetailsChange}
                />
              </Form.Control>
            </Form.Field>
          </Flex>
        </Dialog.Body>
        <Dialog.Footer className={styles.modalFooter}>
          <Dialog.Close>
            <Button
              variant="tertiary"
              size="large"
              onClick={(): void => {
                BufferTracker.churnSurveyCTAClicked({
                  organizationId,
                  ctaName: 'doNotCancel_churnSurvey',
                  ...commonTrackingProperties,
                })
              }}
            >
              No, Do Not Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="critical"
            size="large"
            disabled={!isValid}
            onClick={(): void => {
              openModal({
                key: MODALS.confirmCancellation,
                data: {
                  subscriptionType: isOnTrial ? 'trial' : 'plan',
                },
              })
              BufferTracker.churnSurveyCTAClicked({
                organizationId,
                ctaName: 'continue_churnSurvey',
                ...commonTrackingProperties,
              })
              BufferTracker.churnSurveyCompleted({
                organizationId,
                product: 'buffer',
                reason: `${reason}`,
                wasOnTrial: isOnTrial,
                details,
                ...commonTrackingProperties,
              })
            }}
          >
            Confirm
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default ChurnSurveyModal
