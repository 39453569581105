import React from 'react'
import {
  ChannelIcon,
  type ChannelIconProps,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'

import { usePostData } from '../PostCard/PostCardContext'

import styles from './PostCardFooter.module.css'

const capitalize = (s: string): string =>
  s?.charAt(0)?.toUpperCase() + s.slice(1)

const hasIcon = (service: string): service is ChannelIconProps['type'] => {
  const availableIcons = [
    'facebook',
    'googlebusiness',
    'instagram',
    'linkedin',
    'mastodon',
    'pinterest',
    'startPage',
    'tiktok',
    'twitter',
    'youtube',
    'x',
    'threads',
    'bluesky',
  ]

  return availableIcons.includes(service)
}

export const PostedFrom = (): JSX.Element | null => {
  const { channel, status, via } = usePostData()

  if (status !== 'sent') {
    return <></>
  }

  if (via === 'buffer' || via === 'api') {
    return <></>
  }

  const serviceName =
    channel.service === 'twitter' ? 'Twitter / X' : capitalize(channel.service)

  return (
    <>
      <Tooltip content={`This was posted directly from ${serviceName}`}>
        <Text className={styles.publishedFrom}>
          Posted from
          {hasIcon(channel.service) && (
            <ChannelIcon size="small" color="subtle" type={channel.service} />
          )}
          {serviceName}
        </Text>
      </Tooltip>
    </>
  )
}
