import { useEffect } from 'react'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import { useDebounce } from '@buffer-mono/popcorn'

import {
  useAccountId,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'
import type { Service } from '~publish/gql/graphql'
import { channelServices } from '~publish/helpers/channels'

import type { ChannelNavChannel } from '../useChannels'

export function filterChannels(
  channels: ChannelNavChannel[],
  searchQuery: string | null | undefined,
): ChannelNavChannel[] {
  if (!searchQuery || searchQuery.trim() === '') return channels

  return channels.filter(
    (channel) =>
      channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      channel.service.toLowerCase().includes(searchQuery.toLowerCase()),
  )
}

export const getChannelSuggestions = (
  channels: ChannelNavChannel[],
): Service[] | null => {
  if (channels.length > 3) {
    return null
  }

  const connectedChannelServices = channels.map((channel) => channel.service)

  return channelServices.filter(
    (service) => !connectedChannelServices.includes(service),
  )
}

export function useSearchTracking(
  searchQuery: string,
  channels: ChannelNavChannel[],
): void {
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)
  const organizationId = useCurrentOrganization()?.id
  const accountId = useAccountId()

  useEffect(
    function trackChannelsSearch() {
      if (debouncedSearchQuery.trim() === '') {
        return
      }
      const filteredChannels = filterChannels(channels, debouncedSearchQuery)
      BufferTracker.sidebarChannelsFiltered({
        organizationId: organizationId ?? '',
        accountId: accountId || '',
        clientName: Client.PublishWeb,
        product: 'publish',
        searchQuery: debouncedSearchQuery,
        channelCount: channels.length,
        filteredChannelCount: filteredChannels.length,
        channelIDs: channels.map((channel) => channel.id),
        filteredChannelIDs: filteredChannels.map((channel) => channel.id),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to track when the search query changes
    [debouncedSearchQuery],
  )
}
