import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import Text from '@bufferapp/ui/Text'
import { NotificationScopes, QueueingTypes } from '../../AppConstants'
import AppStore from '../../stores/AppStore'
import { draftPropType } from '../ComposerPropTypes'
import NotificationContainer from '../NotificationContainer'
import { Tooltip } from '@buffer-mono/popcorn'
import ComposerStore from '../../stores/ComposerStore'
import { NotificationStore } from '../../stores/NotificationStore'
import styles from '../css/Composer.module.css'
import { TwitterErrorContainer } from './styles'
import type { Draft } from '../../entities/Draft'

const EditorNotices = ({ draft }: { draft: Draft }): JSX.Element => {
  const isExpanded = AppStore.getExpandedComposerId() === draft.id
  const composerFeedbackMessages = AppStore.getComposerFeedbackMessages(draft)

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const { isEnabled: isHideTwitterConnectionEnabled } = useSplitEnabled(
    'hide-twitter-connection-option',
  )

  const shouldShowTwitterErrorBanner =
    draft.service.isTwitter() && isHideTwitterConnectionEnabled && isExpanded

  const visibleNotifications = NotificationStore.getVisibleNotifications()
  const composerNotPrefilledNoticeScope = `${NotificationScopes.COMPOSER_NOTICE_NOT_PREFILLED}-${draft.service.name}`
  const hasComposerNotPrefilledNotice = visibleNotifications.some(
    (n) => n.scope === composerNotPrefilledNoticeScope,
  )
  const hasComposerBeenCollapsed =
    AppStore.getAppState().composersWhichHaveBeenCollapsed.has(draft.id)

  let shouldShowComposerFeedbackMessages =
    isExpanded &&
    composerFeedbackMessages.length > 0 &&
    hasComposerBeenCollapsed &&
    !AppStore.getIsOmniboxEnabled() &&
    !hasComposerNotPrefilledNotice &&
    !draft.hasThread()

  /**
   * HACK: Remove this when we no longer need to prevent videos
   * outside the correct aspect ratio for IG from being added to
   * the queue.
   *
   * Explanation: `shouldShowComposerFeedbackMessages` above is
   * false sometimes to prevent showing users messages like "please
   * enter text" when they have only just opened the composer, but
   * that also prevents in many cases the warning about incorrect
   * video aspect ratios from showing, so here we hack that to show.
   * This will all be removed shortly once we start allowing these
   * videos and send them as reminders instead.
   */
  if (
    !isRemindersEnabled &&
    composerFeedbackMessages.length > 0 &&
    isExpanded &&
    draft.service.isInstagram() &&
    draft.hasVideoAttachment()
  ) {
    shouldShowComposerFeedbackMessages = true
  }

  const hasComposerFbAutocompleteDisabledNotice = visibleNotifications.some(
    (n) =>
      n.scope === NotificationScopes.COMPOSER_FACEBOOK_AUTOCOMPLETE_DISABLED,
  )

  const shouldShowComposerNotPrefilledNotice =
    isExpanded && hasComposerNotPrefilledNotice && draft.canType()
  const showComposerFbAutocompleteDisabledNotice =
    isExpanded && hasComposerFbAutocompleteDisabledNotice

  const showFbIgLinkPreviewNotice =
    isExpanded &&
    visibleNotifications.some(
      (n) => n.scope === NotificationScopes.FB_IG_URL_NO_LINK_PREVIEW,
    )

  const hasTwitterDuplicateWarningNotice = visibleNotifications.some(
    (n) => n.scope === NotificationScopes.TWITTER_DUPLICATE_CONTENT_WARNING,
  )

  const shouldShowTwitterDuplicateContentWarningNotice =
    isExpanded &&
    hasTwitterDuplicateWarningNotice &&
    draft.service.shouldShowDuplicateContentWarning

  const noticeClassNames = {
    container: styles.composerInfoMessageContainer,
    notification: styles.composerInfoMessage,
    notificationCloseButton: styles.composerInfoMessageCloseButton,
  }

  const shouldShowAlertIcons =
    AppStore.getAppState().whatPreventsSaving.filter(
      (what) => what.composerId === draft.id,
    ).length > 0 && AppStore.getIsOmniboxEnabled() === false

  const shouldShowOmniboxNotices =
    visibleNotifications.some(
      (notif) =>
        notif.scope === NotificationScopes.MC_OMNIBOX_EDIT_NOTICE &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        notif.data.id === draft.id,
    ) && !isExpanded

  const getAlertIconTooltipMarkup = () => {
    const messages = AppStore.getAppState().whatPreventsSaving.reduce(
      (alertMessages, what) =>
        what.composerId === draft.id
          ? // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            alertMessages.concat(what.message)
          : alertMessages,
      [],
    )

    if (messages.length < 1) {
      return null
    }

    return messages.length === 1
      ? messages
      : messages.map((message) => <p key={message}>{`• ${message}`}</p>)
  }

  const getOmniboxNoticeTooltipMarkup = () => {
    const messages = visibleNotifications.reduce(
      (notifMessages, notif) =>
        notif.scope === NotificationScopes.MC_OMNIBOX_EDIT_NOTICE &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        notif.data.id === draft.id
          ? // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            notifMessages.concat(notif.message)
          : notifMessages,
      [],
    )

    if (messages.length < 1) {
      return null
    }

    const uniqueMessages = [...new Set(messages)]

    return uniqueMessages.length === 1
      ? uniqueMessages
      : uniqueMessages.map((message) => <p key={message}>{`• ${message}`}</p>)
  }

  const savedComposerFeedbackClassNames = [
    styles.savedComposerFeedback,
    'bi bi-checkmark',
  ].join(' ')

  const addedToQueueCopyMap = new Map([
    [QueueingTypes.QUEUE, 'Added to queue!'],
    [QueueingTypes.NEXT, 'Added to queue!'],
    [QueueingTypes.NOW, 'Shared!'],
    [QueueingTypes.CUSTOM, 'Scheduled!'],
    [QueueingTypes.SAVE, 'Saved!'],
    [QueueingTypes.ADD_DRAFT, 'Saved as draft!'],
    [QueueingTypes.NEXT_DRAFT, 'Saved as draft!'],
    [QueueingTypes.CUSTOM_DRAFT, 'Saved as draft!'],
  ])

  return (
    <>
      {shouldShowTwitterErrorBanner && (
        <TwitterErrorContainer type="alert" disableAnimation>
          <Text>
            Due to ongoing changes at Twitter, some of Buffer’s Twitter features
            (including scheduled posts) may not work
          </Text>
        </TwitterErrorContainer>
      )}

      {draft.isSaved && !ComposerStore.areAllDraftsSaved() && (
        <div className={savedComposerFeedbackClassNames}>
          {addedToQueueCopyMap.get(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            AppStore.getAppState().draftSaveQueueingType,
          )}
        </div>
      )}

      {shouldShowAlertIcons && !isExpanded && (
        <Tooltip content={getAlertIconTooltipMarkup()}>
          <div className={['bi bi-warning', styles.alertIcon].join(' ')} />
        </Tooltip>
      )}
      {shouldShowOmniboxNotices && (
        <Tooltip content={getOmniboxNoticeTooltipMarkup()}>
          <div
            className={['bi bi-notification', styles.noticeIcon].join(' ')}
          ></div>
        </Tooltip>
      )}

      {shouldShowComposerFeedbackMessages &&
        composerFeedbackMessages.map((what) => (
          <div
            className={styles.composerFeedbackMessage}
            key={`${draft.id}-${what.message}`}
          >
            {what.message}
            {/* @ts-expect-error TS(2339) FIXME: Property 'extra' does not exist on type 'Validatio... Remove this comment to see the full error message */}
            {what.extra && what.extra}
          </div>
        ))}

      {shouldShowComposerNotPrefilledNotice && (
        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={composerNotPrefilledNoticeScope}
          classNames={noticeClassNames}
          showCloseIcon
        />
      )}

      {shouldShowTwitterDuplicateContentWarningNotice && (
        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={NotificationScopes.TWITTER_DUPLICATE_CONTENT_WARNING}
          classNames={noticeClassNames}
          showCloseIcon
        />
      )}

      {showComposerFbAutocompleteDisabledNotice && (
        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={NotificationScopes.COMPOSER_FACEBOOK_AUTOCOMPLETE_DISABLED}
          classNames={noticeClassNames}
          showCloseIcon
        />
      )}

      {showFbIgLinkPreviewNotice && (
        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={NotificationScopes.FB_IG_URL_NO_LINK_PREVIEW}
          classNames={noticeClassNames}
          showCloseIcon
        />
      )}
    </>
  )
}

EditorNotices.propTypes = {
  draft: draftPropType.isRequired,
}

export default EditorNotices
