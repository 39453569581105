const UploadcareIntegrations = {
  GOOGLE_DRIVE: 'gdrive',
  DROPBOX: 'dropbox',
  ONE_DRIVE: 'onedrive',
  CAMERA: 'camera',
  URL: 'url',
  GOOGLE_PHOTOS: 'gphotos',
  EDIT: 'edit',
}

const UploadcareEventNames = {
  UPLOADCARE_EDIT_CLICKED: 'Uploadcare Edit Clicked',
  UPLOADCARE_INTEGRATION_BUTTON_CLICKED:
    'Uploadcare Integration Button Clicked',
  UPLOADCARE_MODAL_OPENED: 'Uploadcare Modal Opened',
  UPLOADCARE_UPLOAD_STARTED: 'Uploadcare Upload Started',
  UPLOADCARE_UPLOAD_FINISHED: 'Uploadcare Upload Finished',
}

const UploadcareSources = {
  BAR: 'bar',
  EDIT: 'edit',
} as const

const gifsAllowed = [
  'pinterest',
  'twitter',
  'linkedin',
  'facebook',
  'instagram',
  'omni',
  'startPage',
  'mastodon',
]

const ServiceCropMap = {
  instagram: [
    'free',
    '1080x1350 minimum',
    '1080x1080 minimum',
    '1080x566 minimum',
  ],
  instagramStory: ['1080x1920 minimum'],
  twitter: ['free', '1:1', '4:3', '16:9', '2:1'],
  mastodon: ['free', '1:1', '4:3', '16:9', '2:1'],
  facebook: ['free', '1:1', '4:3', '9:16'],
  pinterest: ['free', '1:1', '2:3'],
  linkedin: ['free', '1:1', '4:1', '3:4', '16:9'],
  googlebusiness: ['free', '4:3'],
  startPage: ['free', '1:1'],
  omni: ['free', '1:1'],
  ideas: ['free', '1:1', '16:9', '9:16'],
  threads: ['free', '1:1', '4:3', '16:9', '9:16'],
  bluesky: ['free', '1:1', '4:3', '16:9', '9:16'],
  tiktok: ['free', '4:3', '16:9', '9:16', '1:1'],
  youtube: [],
}

export {
  UploadcareIntegrations,
  UploadcareEventNames,
  UploadcareSources,
  ServiceCropMap,
  gifsAllowed,
}
